export var StatusCodes;
(function (StatusCodes) {
    StatusCodes["SUCCESS"] = "VR200";
    StatusCodes["NO_CONTENT"] = "VR204";
    StatusCodes["NOT_FOUND"] = "VR404";
    StatusCodes["AUTO_NOT_EXIST"] = "AC001";
    StatusCodes["NOT_ENOUGH_CONTENTS_IN_CATEGORY"] = "AC009";
    StatusCodes["AUTO_FIRST"] = "AC011";
    StatusCodes["EXPIRED_AUTH"] = "AU703";
    StatusCodes["INVALID_KEY"] = "AU704";
    StatusCodes["UNDER_AGE"] = "AU705";
    StatusCodes["NEED_JOIN"] = "AU706";
    StatusCodes["INVALID_BIRTH"] = "AU707";
    StatusCodes["INVALID_LEGAL_NUMBER"] = "AU708";
    StatusCodes["INVALID_NUMBER"] = "AU709";
    StatusCodes["INVALID_TYPE"] = "AU710";
    StatusCodes["INVALID_LEGAL"] = "AU711";
    StatusCodes["ALREADY_AUTH"] = "AU713";
    StatusCodes["INVALID_AUTH"] = "AU714";
    StatusCodes["TOO_MANY_FAIL"] = "AU716";
    StatusCodes["EXPIRED"] = "AU718";
    StatusCodes["UNABLE_TO_JOIN_ADDTION"] = "AU719";
    StatusCodes["POSSIBLE_RECOVER"] = "AU727";
    StatusCodes["NOT_VCOLORING_JOIN"] = "AU733";
    StatusCodes["NO_CI"] = "AU734";
    StatusCodes["SAME_CI"] = "AU735";
    StatusCodes["CHANGE_MDN"] = "AU737";
    StatusCodes["REST_USER"] = "AU745";
    StatusCodes["AUTH_SMS_FAILURES_EXCEEDED"] = "AU754";
    StatusCodes["REST_USER_AUTH"] = "AU747";
    StatusCodes["INVALID_MNO"] = "AU902";
    StatusCodes["INVALID_INFO_NUMBER"] = "AU903";
    StatusCodes["KT_ERROR_EXCEPTION"] = "AU909";
    StatusCodes["NO_RMC_REQUEST_CONTENT"] = "C929";
    StatusCodes["COUPON_NOT_FOUND"] = "CP001";
    StatusCodes["COUPON_ALREADY_REGISTED"] = "CP003";
    StatusCodes["COUPON_NO_AUTHORIZATION"] = "CP004";
    StatusCodes["COUPON_REGISTER_INVALID_STATE"] = "CP005";
    StatusCodes["COUPON_USE_INVALID_STATE"] = "CP006";
    StatusCodes["COUPON_NOT_USABLE_PERIOD"] = "CP007";
    StatusCodes["COUPON_REGISTER_LIMIT_EXCEEDED"] = "CP009";
    StatusCodes["COUPON_REGISTER_INVALID_PERIOD"] = "CP010";
    StatusCodes["ADOT_USER"] = "FE005";
    StatusCodes["NOT_VOICE_COLORING_MEMBER"] = "GW108";
    StatusCodes["NOT_VOICE_COLORING_MEMBER_KT"] = "GW512";
    StatusCodes["TEMP_NOT_CI"] = "SC008";
    StatusCodes["ALREADY_REGISTERED_ACCOUNT"] = "SC012";
    StatusCodes["ALREADY_REGISTERED_SOCIAL"] = "SC015";
    StatusCodes["NO_PUSH_TERMS"] = "T804";
    StatusCodes["NOT_JOIN_ASSOCIATE"] = "UG001";
    StatusCodes["NOT_JOIN_UNDER_AGE"] = "UG002";
    StatusCodes["NOT_JOIN_CHANGEABLE"] = "UG003";
    StatusCodes["NOT_JOIN_SAME_VOUCHER"] = "UG004";
    StatusCodes["FAIL_CHANGE_VOUCHER"] = "UG006";
    StatusCodes["FAIL_RETRY_CHANGE_VOUCHER"] = "UG007";
    StatusCodes["PURCHASE_UNABLED"] = "UU105";
    StatusCodes["ALREADY_RECEIVE_PRESENT"] = "UU107";
    StatusCodes["EXISTED_CALLER_COLORING"] = "UU203";
    StatusCodes["NO_USER"] = "UU206";
    StatusCodes["LIMITED_CALLER_CONTENTS"] = "UU210";
    StatusCodes["LIMITED_CALLER"] = "UU211";
    StatusCodes["LIMITED_TIME_CONTENTS"] = "UU212";
    StatusCodes["AUTO_COLORING_ON"] = "UU215";
    StatusCodes["CHANGED_NUMBER"] = "UU305";
    StatusCodes["CANCELED_NUMBER"] = "UU307";
    StatusCodes["ALREADY_OWNED"] = "UU101";
    StatusCodes["ALREADY_PURCHASE"] = "UU551";
    StatusCodes["PROMOTION_END"] = "UU601";
    StatusCodes["PROMOTION_PARTICIPATED"] = "UU602";
    StatusCodes["PROMOTION_UNABLE"] = "UU603";
    StatusCodes["PROMOTION_UNDERAGE"] = "UU604";
    StatusCodes["UNABLE_WITHDRAW_REGULAR"] = "AU732";
    StatusCodes["UNVABLE_GIFT"] = "GI002";
    StatusCodes["ALREADY_GIFT"] = "GI003";
    StatusCodes["NOT_GIFT_RECIEVER"] = "GI004";
    StatusCodes["EXIST_MANUAL_PROD"] = "GI005";
    StatusCodes["NON_MEMBER"] = "GI006";
    StatusCodes["LEAVE_USER"] = "GI007";
    StatusCodes["USING_TICKET"] = "GI010";
})(StatusCodes || (StatusCodes = {}));
export var ExceptionCodes;
(function (ExceptionCodes) {
    ExceptionCodes["SUCCESS"] = "VR200";
    ExceptionCodes["NO_CONTENT"] = "VR204";
    ExceptionCodes["AUTO_NOT_EXIST"] = "AC001";
    ExceptionCodes["NOT_ENOUGH_CONTENTS_IN_CATEGORY"] = "AC009";
    ExceptionCodes["AUTO_FIRST"] = "AC011";
    ExceptionCodes["POSSIBLE_RECOVER"] = "AU727";
    ExceptionCodes["NOT_VCOLORING_JOIN"] = "AU733";
    ExceptionCodes["KT_ERROR_EXCEPTION"] = "AU909";
    ExceptionCodes["NO_RMC_REQUEST_CONTENT"] = "C929";
    ExceptionCodes["NOT_VOICE_COLORING_MEMBER"] = "GW108";
    ExceptionCodes["ADOT_USER"] = "FE005";
    ExceptionCodes["TEMP_NOT_CI"] = "SC008";
    ExceptionCodes["ALREADY_REGISTERED_ACCOUNT"] = "SC012";
    ExceptionCodes["FAIL_CHANGE_VOUCHER"] = "UG006";
    ExceptionCodes["FAIL_RETRY_CHANGE_VOUCHER"] = "UG007";
    ExceptionCodes["ALREADY_RECEIVE_PRESENT"] = "UU107";
    ExceptionCodes["AUTO_COLORING_ON"] = "UU215";
    ExceptionCodes["CHANGED_NUMBER"] = "UU305";
    ExceptionCodes["CANCELED_NUMBER"] = "UU307";
    ExceptionCodes["ALREADY_PURCHASE"] = "UU551";
    ExceptionCodes["UNABLE_WITHDRAW_REGULAR"] = "AU732";
})(ExceptionCodes || (ExceptionCodes = {}));
export var ExceptionApis;
(function (ExceptionApis) {
    ExceptionApis["SIGN_IN"] = "/sign-in";
    ExceptionApis["WITHDRAW_EXTRA_SERVICE"] = "/withdraw/extra-service";
    ExceptionApis["RETRY"] = "/retry";
    ExceptionApis["AUTH_SOCIAL_TOKEN"] = "/auth/social/token";
    ExceptionApis["USER_GIFT_RECEIVE"] = "/user/gift/receive";
    ExceptionApis["USER_PURCHASE_PRE_CHECK"] = "/user/purchase/pre-check";
    ExceptionApis["SIGN_UP"] = "/sign-up";
    ExceptionApis["AUTO_COLORING_DETAIL"] = "/user/auto-coloring/detail";
})(ExceptionApis || (ExceptionApis = {}));
