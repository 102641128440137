import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
export var EVENT_NAME = 'outlink_web';
export var firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_OUTLINK_FB_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_OUTLINK_FB_API_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_OUTLINK_FB_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_OUTLINK_FB_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_OUTLINK_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_OUTLINK_FB_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_OUTLINK_FB_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_OUTLINK_FB_MEASUREMENT_ID,
};
var sendEvent = function (analytics) { return function () {
    logEvent(analytics, EVENT_NAME, { item_name: "\uC678\uBD80\uB9C1\uD06C_".concat(window.location.href) });
}; };
export var firebaseInit = function () {
    var send = sendEvent(getAnalytics(initializeApp(firebaseConfig)));
    var aList = document.getElementsByClassName('firebase-link');
    if (aList) {
        Array.from(aList).forEach(function (node) { return node.addEventListener('click', send); });
    }
};
