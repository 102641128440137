export var isIOS = function () {
    return (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].some(function (agent) { return navigator.userAgent.indexOf(agent) >= 0; }) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.indexOf('Mac') >= 0 && 'ontouchend' in document));
};
export var isApp = function () {
    return navigator.userAgent.indexOf('VRBT_APP') > -1;
};
export var openDialog = function (message, callback) {
    var container = document.getElementById('wrapper');
    if (!container) {
        return;
    }
    var outer = document.createElement('div');
    outer.setAttribute('class', 'pop-outer');
    outer.addEventListener('click', function (e) {
        if (e.target.id === 'confirm-button') {
            callback();
        }
    });
    outer.innerHTML = "<div class=\"cmm-dimmed-layer\"></div><div class=\"pop-inner\"><div class=\"base-txt-wrap\"><div class=\"base-txt-box\"><p class=\"g-txt\" style=\"white-space: pre-wrap;\">".concat(message, "</p></div></div><div class=\"pop-btn-module\"><button type=\"button\" class=\"acc\" id=\"confirm-button\">\uD655\uC778</button></div></div>");
    container.appendChild(outer);
};
export var closeDialog = function () {
    var container = document.getElementById('wrapper');
    if (!container) {
        return;
    }
    var dialog = container.getElementsByClassName('pop-outer');
    container.removeChild(dialog[0]);
};
