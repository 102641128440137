import { ANDROID_PACKAGE } from '../enum/Constants';
import { firebaseInit } from './firebase';
import { closeDialog, isIOS, openDialog } from './utils';
import '../../public/static/css/join_pc.css';
import '../../public/static/css/common.css';
var goAppLink = function () {
    if (isIOS()) {
        openDialog('iOS 앱은 준비중입니다.', closeDialog);
    }
    else {
        window.open("https://play.google.com/store/apps/details?id=".concat(ANDROID_PACKAGE));
    }
};
var handleClickWeb = function () {
    openDialog('V 컬러링 웹 버전은 모바일에서만 이용하실 수 있습니다.\n모바일에서 https://www.vcoloring.com으로 접속하세요', closeDialog);
};
var initialize = function () {
    firebaseInit();
    var btn = document.getElementById('download-btn');
    var web = document.getElementById('web-btn');
    if (btn) {
        btn.addEventListener('click', goAppLink);
    }
    if (web) {
        web.addEventListener('click', handleClickWeb);
    }
};
initialize();
